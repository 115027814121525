<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        
        <!-- annual_holiday -->
        <b-col md="6">
          <b-form-group
            label="Congés personnel payé par an"
            label-for="annual_holiday"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="annual_holiday"
                v-model="annual_holiday"
                name="annual_holiday"
                type="number"
                placeholder="14"
              />
            </b-input-group>
          </b-form-group>
        </b-col> 

        <!-- reget_holiday -->
        <b-col md="6">
          <b-form-group
            label="Congés de recuperation"
            label-for="reget_holiday"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="reget_holiday"
                v-model="reget_holiday"
                name="reget_holiday"
                type="number"
                placeholder="14"
              />
            </b-input-group>
          </b-form-group>
        </b-col> 

        <!-- circonstance_holiday -->
        <b-col md="6">
          <b-form-group
            label="Congés de circonstance"
            label-for="circonstance_holiday"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="circonstance_holiday"
                v-model="circonstance_holiday"
                name="circonstance_holiday"
                type="number"
                placeholder="14"
              />
            </b-input-group>
          </b-form-group>
        </b-col> 

        <!-- birth_holiday -->
        <b-col md="6">
          <b-form-group
            label="Congés de maternité"
            label-for="birth_holiday"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="birth_holiday"
                v-model="birth_holiday"
                name="birth_holiday"
                type="number"
                placeholder="14"
              />
            </b-input-group>
          </b-form-group>
        </b-col> 

        <!-- other_holiday -->
        <b-col md="6">
          <b-form-group
            label="Autres Congés"
            label-for="other_holiday"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="other_holiday"
                v-model="other_holiday"
                name="other_holiday"
                type="number"
                placeholder="14"
              />
            </b-input-group>
          </b-form-group>
        </b-col> 

        <!-- paid_holiday_interval -->
        <b-col md="6">
          <b-form-group
            label="Intervale Min. Jours Congés"
            label-for="paid_holiday_interval"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="paid_holiday_interval"
                v-model="paid_holiday_interval"
                name="paid_holiday_interval"
                type="number"
                placeholder="60"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="postCorporationSettingHoliday"
          >
            Enregistrer
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mt-1"
          >
            Annuler
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { $themeConfig } from "@themeConfig";
import axios from "axios"; 

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  props: {
    holidayData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    //
    return {
      localOptions: JSON.parse(JSON.stringify(this.holidayData)),
      annual_holiday: JSON.parse(JSON.stringify(this.holidayData)).annual_holiday, //
      reget_holiday: JSON.parse(JSON.stringify(this.holidayData)).reget_holiday, //
      circonstance_holiday: JSON.parse(JSON.stringify(this.holidayData)).circonstance_holiday, //
      birth_holiday: JSON.parse(JSON.stringify(this.holidayData)).birth_holiday, //
      other_holiday: JSON.parse(JSON.stringify(this.holidayData)).other_holiday, //
      paid_holiday_interval: JSON.parse(JSON.stringify(this.holidayData)).paid_holiday_interval,
      errors: [],
    };
  },
  methods: {
    async postCorporationSettingHoliday() {
      // for the web app //
      let formData = new FormData();
      formData.append("annual_holiday", this.annual_holiday);
      formData.append("reget_holiday", this.reget_holiday);
      formData.append("circonstance_holiday", this.circonstance_holiday);
      formData.append("birth_holiday", this.birth_holiday);
      formData.append("other_holiday", this.other_holiday);
      formData.append("paid_holiday_interval", this.paid_holiday_interval);
      //
      await myApi
        .post("update_corporation_infos", formData)
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 10000,
              showCancelButton: true,
              confirmButtonText: "Continuer",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-warning ml-1",
              },
              buttonsStyling: false,
            });
          } else {
            this.$swal({
              title: "Erreur Survenu",
              text: response.data.message,
              icon: "error",
              timer: 10000,
              showCancelButton: false,
              confirmButtonText: "Continuer",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "btn btn-warning",
                cancelButton: "btn btn-outline-error ml-1",
              },
              buttonsStyling: false,
            });
          }
        })
        .catch((error) => {
          this.$swal({
            title: "Erreur Survenu",
            text: error.message,
            icon: "error",
            timer: 10000,
            showCancelButton: false,
            confirmButtonText: "Continuer",
            cancelButtonText: "Annuler",
            customClass: {
              confirmButton: "btn btn-warning",
              cancelButton: "btn btn-outline-error ml-1",
            },
            buttonsStyling: false,
          });
        });
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.holidayData));
    },
  },
};
</script>
